import { Button, Typography } from "@mui/material";
import styled from "styled-components";
import colors from "styles/colors";

export const StyledHeroContainer = styled.div`
  background: ${colors.CULTURED};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  min-height: 752px;
  padding: 112px 155px;
  width: 100%;
  overflow-x: hidden;

  @media all and (max-width: 1200px) {
    flex-direction: column;
    padding: 40px 24px;
  }
`;

export const StyledHeroColumn = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 440px;
  margin-top: 70px;
  padding: 0px;
  width: 555px;

  @media all and (max-width: 1200px) {
    margin-bottom: 64px;
    width: 100%;
  }
`;

export const StyledHeroImg = styled.img`
  box-sizing: border-box;
  height: 640px;
  margin-bottom: -112px;
  margin-left: auto;
  margin-right: -155px;
  width: 674px;

  @media all and (max-width: 1200px) {
    height: 356px;
    margin-bottom: -40px;
    margin-right: -24px;
    width: 375px;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${colors.CORAL[4]} !important;
  height: 48px;
  width: 260px;

  @media all and (max-width: 1200px) {
    width: calc(100% - 24px);
  }
`;

export const TitleTypography = styled(Typography)`
  @media all and (max-width: 1200px) {
    font-size: 48px !important;
    font-weight: 500 !important;
    line-height: 68px !important;
  }
`;
